import React from 'react'
import PropTypes from 'prop-types'

import Galleries from './Galleries'

import pic01 from '../images/beach.jpg'
import pic02 from '../images/puddle.jpg'
import pic03 from '../images/mic.jpeg'
import pic04 from '../images/smalltalksbanner.png'

import st2 from '../images/st2-10.jpg'
import events from '../images/st2-6.jpg'

class Main extends React.Component {
  render() {


    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="intro" className={`${this.props.article === 'intro' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">About</h2>
          <span className="image main"><img src={pic03} alt="" /></span>
          <h4>Share What You Are Passionate About</h4>
          <p>Small Talks is an event where our community gathers and shares fresh ideas that they are passionate about. Think TED talks, but on a much more intimate scale.</p>
          <p>Our purpose is to help our Mobile Bay community grow their inspiration through gathering and sharing stories and ideas through short talks. You can come talk about 
            anything you want.</p><p>Come tell us how to bake the perfect cake. Talk to us about Quantum Mechanics. Tell us about the first time you fell in love. Anything goes. Our only rule is that participants talk about something they are passionate about!
           
            What we want is to hear something that inspires or interests you, something that you might not get the chance to talk about normally. We hope that people leave these events with some new knowledge, new inspiration, and new friends with similar interests.
          </p>
          <p>
            Each Small Talk event will consist of three or four speakers who will present for around ten minutes with a Q&amp;A at the end. We will choose a theme based around the topics that the speakers want to share about. Not a professional communicator? No problem!
            We value sincerety and enthusiasm more than anything and if you have something to say, we want to hear it.
          </p>
          <p>Interested in participating? Contact us: <a href="mailto:smalltalksmobilebay@gmail.com">smalltalksmobilebay@gmail.com</a> </p>
          {close}
        </article>

        <article id="work" className={`${this.props.article === 'work' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Events</h2>
          <span className="image main"><img src={events} alt="" /></span>
          <h4>Upcoming Events</h4>
          <h3>Small Talks #3</h3>
          <h4>April 2nd - Alabama Contemporary Art Center <br/> 301 Conti Street Mobile, AL - More Details TBA</h4>
          <p></p>
          <p>For more information on how to get involved in future events, contact us at <a href="mailto:smalltalksmobilebay@gmail.com">smalltalksmobilebay@gmail.com</a></p>
          {close}
        </article>

        <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Archive</h2>
          <span className="image main"><img src={st2} alt="" /></span>
          <h3>Pictures/Videos/Press</h3>
          <h5>Small Talks - All That We Wear</h5>
          <p>Small Talks: All That We Wear was a fun evening centered around fashion and style! We heard from Richard McGill, who shared about Mobile Fashion week and his personal relationship with fashion,
            Courtney Matthews, who talked about her work as a designer and makeup artist and her personal style, we also heard from Trenton Gates who shared about his love of sneakers, specifically Yeezy's! 
            Thank you to everyone who came out! See you in January for the next one!</p>

          <p>Thanks to the Alabama Contemporary Art Center for having us and to Mandy Sullivan for the great photos!</p>
          <Galleries />
          {close}
        </article>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Contact</h2>
          <h4>Hit Us Up!</h4>
          <form method="post" action="https://formspree.io/smalltalksmobilebay@gmail.com">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li><input type="submit" value="Send Message" className="special" /></li>
              <li><input type="reset" value="Reset" /></li>
            </ul>
          </form>
          <ul className="icons">
            <li><a href="https://www.facebook.com/smalltalksmobilebay/" className="icon fa-facebook"><span className="label">Facebook</span></a></li>
            <li><a href="https://www.instagram.com/smalltalksmobilebay/" className="icon fa-instagram"><span className="label">Instagram</span></a></li>
          </ul>
          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main