import React from 'react'
import PropTypes from 'prop-types'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import st1_1 from '../images/st1-1.jpg';
import st1_2 from '../images/st1-2.jpg';
import st1_3 from '../images/st1-3.jpg';
import st1_4 from '../images/st1-4.jpg';
import st1_5 from '../images/st1-5.jpg';
import st1_6 from '../images/st1-6.jpg';
import st1_7 from '../images/st1-7.jpg';
import st1_8 from '../images/st1-8.jpg';
import st1_9 from '../images/st1-9.jpg';
import st1_10 from '../images/st1-10.jpg';
import st1_11 from '../images/st1-11.jpg';
import st1_12 from '../images/st1-12.jpg';

import st2_1 from '../images/st2-1.jpg';
import st2_2 from '../images/st2-2.jpg';
import st2_3 from '../images/st2-3.jpg';
import st2_4 from '../images/st2-4.jpg';
import st2_5 from '../images/st2-5.jpg';
import st2_6 from '../images/st2-6.jpg';
import st2_7 from '../images/st2-7.jpg';
import st2_8 from '../images/st2-8.jpg';
import st2_9 from '../images/st2-9.jpg';
import st2_10 from '../images/st2-10.jpg';
import st2_11 from '../images/st2-11.jpg';



const st1 = [
    st1_1,
    st1_2,
    st1_3,
    st1_4,
    st1_5,
    st1_6,
    st1_7,
    st1_8,
    st1_9,
    st1_10,
    st1_11,
    st1_12
];

const st2 = [
  st2_10,
  st2_4,
  st2_3,
  st2_2,
  st2_5,
  st2_6,
  st2_7,
  st2_8,
  st2_9,
  st2_1,
  st2_11,
]


class Galleries extends React.Component {
    constructor(props) {
        super(props);
     
        this.state = {
          photoIndex: 0,
          st1IsOpen: false,
          st2IsOpen: false
        };
      }
     
      render() {
        const { photoIndex, st1IsOpen, st2IsOpen } = this.state;
     
        return (
          <div>
            <button type="button" onClick={() => this.setState({ st2IsOpen: true })}>
                ST#2 - "All That We Wear"
            </button>
            <br></br>
            <br></br>
            <button type="button" onClick={() => this.setState({ st1IsOpen: true })}>
                ST#1 - "Labor of Love"
            </button>

          {st2IsOpen && (
              <Lightbox
                mainSrc={st2[photoIndex]}
                nextSrc={st2[(photoIndex + 1) % st2.length]}
                prevSrc={st2[(photoIndex + st2.length - 1) % st2.length]}
                onCloseRequest={() => this.setState({ st1IsOpen: false })}
                onMovePrevRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + st2.length - 1) % st2.length,
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + 1) % st2.length,
                  })
                }
              />
            )}
     
            {st1IsOpen && (
              <Lightbox
                mainSrc={st1[photoIndex]}
                nextSrc={st1[(photoIndex + 1) % st1.length]}
                prevSrc={st1[(photoIndex + st1.length - 1) % st1.length]}
                onCloseRequest={() => this.setState({ st2IsOpen: false })}
                onMovePrevRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + st1.length - 1) % st1.length,
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + 1) % st1.length,
                  })
                }
              />
            )}
          </div>
        );
      
    }
}

export default Galleries